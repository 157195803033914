.weightField {
  input {
    height: 28px;
    width: 80px;
    font-size: 14px;
    border-radius: 2px;
    font-weight: 500;
    border-color: transparent;
    cursor: pointer;
    margin-right: 12px;
    margin-top: 2px;
    padding-left: 6px;
    background-color: transparent;
    &:focus {
      border-color: #254653;
      background-color: white;
    }
  }
}
