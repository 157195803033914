@import '../../styles/structure.scss';
@import '../../styles/colors.scss';

.content {
  a {
    font-weight: 600;
    color: $linkBlue;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  p {
    line-height: 1.5;
    text-align: justify;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    padding-left: 40px;
  }

  li {
    line-height: 1.5;
    list-style-type: disc;
  }
}
