$teal: #254653;
$tealBlack: #373e41;
$tealLightest: #e6f0f4;

$orange: #bc5d2e;

$black: #231f20;

$darkGrey: #666666;

$weightRed: #b45048; // terracotta-500
$weightYellow: #fcbb40;
$weightGreen: #6a8d73;

$extraLightDefaultGray: rgb(250, 250, 250);
$lightDefaultGray: #f6f7f7; // zinc-100
$defaultGray: #ebedee; // zinc-100
$darkDefaultGray: #e0e3e5;

$linkBlue: rgb(0, 124, 208);
